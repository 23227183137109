
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { deepCopy, fixPrice } from '@/utils/general'

@Component({})
  export default class StockPieCost extends BaseChart {
  tooltipValues: string[] = [];
  fixPrice = fixPrice
  colors = []
  labels = []

  @Prop() stockPrices: Record<string, any>
  stockCostAttribute = []

  // Methods
  getStock () {
    const stockCost = this.stockPrices
    if (!stockCost.aggregates) {
      return
    }

    const { labels, series, tooltipValues, colors } = this.transformStockAggregatesData(stockCost.aggregates)

    this.series = series
    this.tooltipValues = tooltipValues
    this.colors = colors
    this.labels = labels
    this.chartOptions = {
      labels,
      colors: colors.map(color => color.split(' ')[0]),
      title: {
        text: `Monto: ${fixPrice(this.stockValue)}`,
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      tooltip: {
        y: {
          formatter: (val: number, options: any) => {
            return this.tooltipValues[options.seriesIndex]
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 1,
            offset: -25,
          },
        },
      },
    }
  }

  transformStockAggregatesData (aggregates) {
    const labels = []
    const series = []
    const tooltipValues = []
    const colors = []

    if (aggregates) {
      let data = deepCopy(aggregates)
      this.stockCostAttribute = data
      const reserveProcess = data.filter(item => item.status.description === 'Reserva en proceso')?.[0]
      data = data.filter(item => item.status.description !== 'Reserva en proceso')
      data.sort((prev, next) => {
        return next.stocks.aggregate.sum.total_cost - prev.stocks.aggregate.sum.total_cost
      })

      data.forEach(item => {
        if (item.status.description !== 'Vendido') {
          if (item.status.description === 'Reservado' && reserveProcess) {
            item.stocks.aggregate.sum.total_cost += reserveProcess.stocks.aggregate.sum.total_cost
          }
          const totalCost = item.stocks.aggregate.sum.total_cost || 0
          labels.push(item.status.description)
          colors.push(item.status.color)
          series.push(totalCost)
          tooltipValues.push(fixPrice(totalCost))
        }
      })
    }

    return { labels, series, tooltipValues, colors }
  }

  // Getters
  get stockValue () {
    let sum = 0
    this.series.forEach(el => {
      sum += el
    })
    return sum
  }

  // Watchers
  @Watch('stockPrices', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getStock()
    this.display = true
  }
  }
