
  import dayjs from 'dayjs'
  import Component from 'vue-class-component'
  import { mapActions } from 'vuex'
  import { filters } from './filters'
  import { GForm } from '@/components/forms/GForm'
  import PeriodPurchases from '../../PeriodPurchases.vue'
  import PeriodSales from '../../PeriodSales.vue'
  import PeriodCredits from '../../PeriodCredits.vue'
  import UtilityPeriodSales from '../../UtilityPeriodSales.vue'
  import UtilityPeriodCredits from '../../UtilityPeriodCredits.vue'
  import UtilityPeriodTotal from '../../UtilityPeriodTotal.vue'
  import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    PeriodPurchases,
    PeriodSales,
    PeriodCredits,
    UtilityPeriodSales,
    UtilityPeriodCredits,
    UtilityPeriodTotal,
  },
  methods: {
    ...mapActions('dashboard', [
      'getPurchasesAmount',
      'getPurchasesTarget',
      'getSalesByDay',
      'getSalesAmount',
      'getSalesTarget',
      'getCreditsByDay',
      'getCreditsTarget',
      'getUtilityCreditsByDay',
      'getStockStatus',
      'getStockPrices',
      'getExecutivesForReports',
    ]),
  },
})
  export default class StaffDashboard extends GForm {
  menu = false
  loading = false
  currentDate = dayjs()
  endOfMonth = this.currentDate.endOf('month')
  daysTillMonthEnd = this.endOfMonth
    .diff(this.currentDate, 'days')

  monthLenght = this.endOfMonth
    .diff(this.currentDate.startOf('month'), 'days') + 1

  monthPercentage = Math.round((this.currentDate.$D / this.monthLenght) * 100)
  selected = new Date(this.currentDate.format('YYYY/MM/DD')).toISOString().substring(0, 7)
  chartData: Record<string, any> = {
    salesTarget: {},
    creditTarget: {},
    purchasesTarget: {},
    purchasesAmount: {},
    sales: {},
    credits: {},
    executivesForReports: {},
  }

  getPurchasesAmount!: (variable) => Promise<Record<string, any>>
  getPurchasesTarget!: () => Promise<Record<string, any>>
  getSalesByDay!: (variable) => Promise<Record<string, any>>
  getSalesTarget!: () => Promise<Record<string, any>>
  getCreditsByDay!: (variable) => Promise<Record<string, any>>
  getCreditsTarget!: () => Promise<Record<string, any>>
  getExecutivesForReports!: () => Promise<Record<string, any>>

  async mounted () {
    this.chartData.executivesForReports = await this.getExecutivesForReports()
  }

  // Results data
  calledResults = false

  async getResultsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || this.calledResults) {
      return
    }
    this.loading = true
    this.chartData.purchasesAmount = await this.getPurchasesAmount(this.filters.stockCreatedDate)
    this.chartData.purchasesTarget = await this.getPurchasesTarget()
    this.chartData.sales = await this.getSalesByDay(this.filters.soldDate)
    this.chartData.salesTarget = await this.getSalesTarget()
    this.chartData.credits = await this.getCreditsByDay(this.filters.credit)
    this.chartData.creditTarget = await this.getCreditsTarget()
    this.loading = false

    this.calledResults = true
  }

  get filters () {
    return filters(this.currentMonth, this.idExecutive)
  }

  get prevMonthFilters () {
    return filters(this.prevMonth, this.idExecutive)
  }

  get idExecutive () {
    return this.idEmployee || 1
  }

  get formData () {
    return {
      start: dayjs(this.selected).startOf('month').format('YYYY-MM-DD'),
      end: dayjs(this.selected).endOf('month').format('YYYY-MM-DD'),
    }
  }

  get currentMonth () {
    return {
      _gte: dayjs(this.formData.start).subtract(dayjs().utcOffset(), 'minute').format('YYYY-MM-DDTHH:mm:ss'),
      _lte: `${dayjs(this.formData.end).subtract(dayjs().utcOffset(), 'minute').format('YYYY-MM-DD')}T23:59:59`,
    }
  }

  get prevMonth () {
    return {
      _gte: dayjs(this.formData.start).subtract(1, 'month').startOf('month').subtract(dayjs().utcOffset(), 'minute').format('YYYY-MM-DDTHH:mm:ss'),
      _lte: `${dayjs(this.formData.end).subtract(1, 'month').endOf('month').subtract(dayjs().utcOffset(), 'minute').format('YYYY-MM-DD')}T23:59:59`,
    }
  }

  get dateString () {
    const day = this.currentDate.format('DD')
    const monthNumber = this.currentDate.format('MM')
    let month
    switch (monthNumber) {
      case '01':
        month = 'Enero'
        break
      case '02':
        month = 'Febrero'
        break
      case '03':
        month = 'Marzo'
        break
      case '04':
        month = 'Abril'
        break
      case '05':
        month = 'Mayo'
        break
      case '06':
        month = 'Junio'
        break
      case '07':
        month = 'Julio'
        break
      case '08':
        month = 'Agosto'
        break
      case '09':
        month = 'Septiembre'
        break
      case '10':
        month = 'Octubre'
        break
      case '11':
        month = 'Noviembre'
        break
      default:
        month = 'Diciembre'
        break
    }
    return `${day} de ${month}`
  }

  @Watch('currentMonth', { immediate: true, deep: true })
  @Watch('idExecutive', { immediate: true, deep: true })
  async onDateChange () {
    this.calledResults = false
    // TODO: Uncomment when the corresponding section is added
    // this.calledAccumulateds = false
    // this.calledReports = false
    // this.calledStockData = false
    // this.calledVehicleData = false
    // this.calledStockQuantity = false
    // this.calledFinancing = false
    // this.calledInspectionsData = false
    // this.calledLeadsData = false
    // this.calledClosingReasonData = false
    // this.calledDetailedData = false

    await this.getResultsData(undefined, undefined, true)
  }
  }
