
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailChart } from './detailChart'
  import CarDetail from './Detail/CarDetail.vue'

@Component({
  components: {
    CarDetail,
  },
})
  export default class DetailPurchasesByCar extends DetailChart {
  @Prop() purchases: Record<string, any>
  @Prop() purchasesTarget: Record<string, any>

  amount = 0
  target = 10
  cars = []

  // Methods
  getData () {
    if (!this.purchases?.sales_stock_view_aggregate ||
      !this.purchasesTarget?.settings_process) return
    const salesNumber = this.purchases.sales_stock_view_aggregate?.aggregate?.count
    if (salesNumber !== undefined) {
      this.amount = salesNumber
    }
    const cars = this.purchases?.sales_stock_view_aggregate?.nodes
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.stock_created).diff(dayjs(next.stock_created))
      })
    }
    const purchasesTarget = this.purchasesTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (purchasesTarget) {
      this.target = purchasesTarget
    }
  }

  @Watch('purchases', { immediate: true, deep: true })
  @Watch('purchasesTarget', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
