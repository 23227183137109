
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailChart } from './detailChart'
  import CarDetail from './Detail/CarDetail.vue'

@Component({
  components: {
    CarDetail,
  },
})
  export default class DetailSalesByCar extends DetailChart {
  @Prop() sales: Record<string, any>
  @Prop() salesTarget: Record<string, any>

  amount = 0
  target = 10
  cars = []

  // Methods
  mounted () {
    this.getData()
  }

  getData () {
    if (!this.sales?.sales_stock_aggregate || !this.target) return
    const salesNumber = this.sales.sales_stock_aggregate?.aggregate?.count
    if (salesNumber !== undefined) {
      this.amount = salesNumber
    }
    const cars = this.sales?.sales_stock_aggregate?.nodes
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.sold_date).diff(dayjs(next.sold_date))
      })
    }
    const salesTarget = this.salesTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (salesTarget) {
      this.target = salesTarget
    }
  }

  @Watch('sales', { immediate: true, deep: true })
  @Watch('salesTarget', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
