
  import Component from 'vue-class-component'
  import { Prop } from 'vue-property-decorator'
  import { BaseChart } from '../baseChart'

@Component({})
  export default class ExecutiveDetail extends BaseChart {
  @Prop() executive
  @Prop() target
  @Prop() staffExecutives

  get percentage () {
    return (this.executive.amount / this.individualTarget) * 100
  }

  get individualTarget () {
    return Math.round(this.target * 1.2)
  }

  get isStaff () {
    if (!this.staffExecutives) return false
    const executiveName = this.executive.name.toLowerCase()
    const staff = this.staffExecutives.map(executive => `${executive.first_name} ${executive.surname}`.toLowerCase())
    return staff.includes(executiveName)
  }
  }
