
  import { Debounce } from '@/utils/decorators'
  import { fixPrice } from '@/utils/general'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({})
  export default class CreditsReport extends Vue {
  @Prop() reports
  executives = []
  reportsAttribute = []
  fixPrice = fixPrice

  filterByExecutive (executive) {
    return this.reportsAttribute.filter(report =>
      report.evaluations
        .map(evaluation => evaluation.executiveName)
        .includes(executive)
    )
  }

  filterEvaluationsByCarAndExecutive (car, executive) {
    const reports = this.reportsAttribute
      .filter(report => report.autoDescription === car)
      .map(report => report.evaluations)
      .flat()
      .filter(evaluation => evaluation.executiveName === executive)
      .sort((prev, next) => next.higherFee - prev.higherFee)

    reports.forEach((report, index) => {
      if (reports[index + 1]?.agreementEntity === report.agreementEntity) {
        reports.splice(index + 1, 1)
      }
    })
    return reports
  }

  getData () {
    if (!this.reports.getFinancingFinancialReport) return
    const reports = []
    // eslint-disable-next-line no-unused-vars
    for (const [_key, report] of Object.entries(this.reports.getFinancingFinancialReport)) {
      reports.push(report)
    }
    if (!reports) return
    this.reportsAttribute = reports

    this.filterEvaluationsByCarAndExecutive('', '')
    const executives = []
    reports.forEach(report => {
      if (!executives.includes(report.evaluations[0].executiveName)) {
        executives.push(report.evaluations[0].executiveName)
      }
    })
    this.executives = executives
  }

  get totalCommisions () {
    let count = 0
    this.reportsAttribute.forEach(report => {
      const higherFee = report.evaluations.sort((prev, next) => {
        return prev.higherFee - next.higherFee
      })[0].higherFee
      count += Number(higherFee)
    })
    return count
  }

  @Watch('reports', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }

