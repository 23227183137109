
  import { Debounce } from '@/utils/decorators'
  import { fixPrice } from '@/utils/general'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({})
  export default class FinancialEvaluationReport extends Vue {
  @Prop() reports
  executives = []
  reportsAttribute = []
  fixPrice = fixPrice

  filterByExecutive (executive) {
    return this.reportsAttribute.filter(report =>
      report.executiveName === executive
    )
  }

  getData () {
    if (!this.reports) return
    const reports = this.reports.getAppraisalFinancialReport
    if (!reports) return
    this.reportsAttribute = reports

    const executives = []
    reports.forEach(report => {
      if (!executives.includes(report.executiveName)) {
        executives.push(report.executiveName)
      }
    })
    this.executives = executives
  }

  get totalProyectedUtility () {
    let count = 0
    this.reportsAttribute.forEach(report => count += report.projectedUtility)
    return count
  }

  get totalInvestment () {
    let count = 0
    this.reportsAttribute.forEach(report => {
      count += Number(report.appraised)
    })
    return count
  }

  @Watch('reports', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }

