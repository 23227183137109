
  import Component from 'vue-class-component'
  import { Prop } from 'vue-property-decorator'
  import { DetailChart } from '../detailChart'
  import dayjs from 'dayjs'

@Component({})
  export default class CarDetail extends DetailChart {
  @Prop() car

  get date () {
    if (this.carDate) {
      return dayjs(this.carDate).format('DD/MM/YYYY')
    }
    return ''
  }

  get carDate () {
    if (this.car.stock_created) return this.car.stock_created
    if (this.car.sold_date) return this.car.sold_date
    if (this.car.signed_date) return this.car.signed_date
  }

  get carPlate () {
    if (this.car.auto?.registration_plate) return this.car.auto?.registration_plate
    if (this.car.financing?.sale_order?.deal?.auto?.registration_plate) {
      return this.car.financing?.sale_order?.deal?.auto?.registration_plate
    }
    if (this.car.stock_detail.auto.registration_plate) {
      return this.car.stock_detail.auto.registration_plate
    }
  }

  get executiveFullName () {
    let executive = this.car.deals?.[0]?.lead?.executive
    if (!executive) {
      executive = this.car.executive
    }
    if (!executive) {
      executive = this.car.financing?.sale_order?.deal?.lead?.executive
    }

    if (executive) {
      const name = executive.person?.first_name
      const lastName = executive.person?.surname
      return `${name} ${lastName}`
    }
    return 'Sin ejecutivo'
  }

  get carModel () {
    if (this.car.auto) return this.car.auto
    if (this.car.stock_detail?.auto) {
      return this.car.stock_detail?.auto
    }
    if (this.car.financing?.sale_order?.deal?.auto) {
      return this.car.financing?.sale_order?.deal?.auto
    }
  }

  get carFullName () {
    const brand = this.carModel.version_year?.version?.model?.brand?.name
    const model = this.carModel.version_year?.version?.model?.name
    return `${brand} ${model}`
  }
  }
