
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { deepCopy } from '@/utils/general'

@Component({})
  export default class StockPieTotal extends BaseChart {
  @Prop() stockStatus: Record<string, any>
  stockStatusAttribute = []

  // Methods
  getStock () {
    const stock = this.stockStatus

    if (!stock.aggregates) {
      return
    }

    if (stock.aggregates) {
      let data = deepCopy(stock.aggregates)
      const reserveProcess = data.filter(item => item.status.description === 'Reserva en proceso')?.[0]
      data = data.filter(item => item.status.description !== 'Reserva en proceso')

      const stockStatus = data.map(x => {
        if (x.status.description !== 'Vendido') {
          if (x.status.description === 'Reservado') {
            x.stocks.aggregate.count += reserveProcess.stocks.aggregate.count
          }
          return {
            name: x.status.description,
            color: x.status.color.split(' ')[0],
            account: x.stocks.aggregate.count,
          }
        }
      }).filter(x => x !== undefined)
      stockStatus.sort((prev, next) => {
        return next.account - prev.account
      })
      this.stockStatusAttribute = stockStatus

      this.series = stockStatus.map(stock => stock.account)
      this.chartOptions = {
        labels: stockStatus.map(item => item.name),
        colors: stockStatus.map(item => item.color.split(' ')[0]),
        title: {
          text: `Cantidad: ${this.stockNumber}`,
          align: 'center',
          style: {
            fontFamily: 'Poppins',
          },
        },
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          pie: {
            dataLabels: {
              minAngleToShowLabel: 1,
              offset: -25,
            },
          },
        },
      }
    }
  }

  // Getters
  get stockNumber () {
    let sum = 0
    this.series.forEach(el => {
      sum += el
    })
    return sum
  }

  // Watchers
  @Watch('stockStatus', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getStock()
    this.display = true
  }
  }
