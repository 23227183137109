
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component({})
  export default class PeriodSales extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  @Prop() sales!: Record<string, any>
  @Prop() target!: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives

  getData () {
    if (!this.sales || !this.target) return
    const sales = this.sales
    const targetSales = this.target

    if (!sales) {
      this.series = [0]
      this.chartOptions.fill.colors = this.getBarColor(0)
      return
    }

    let salesNumber = sales?.sales_stock_aggregate?.aggregate?.count
    if (!salesNumber) salesNumber = 0

    let monthlyTarget = Math.round((targetSales?.settings_process?.[0]?.config?.projectedUtility?.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))
    if (!monthlyTarget) monthlyTarget = 10
    // const target = (monthlyTarget / 30) * (dayjs(endDate).diff(startDate, 'day') + 1)

    const percentage = (salesNumber / monthlyTarget) * 100

    this.brief = `${salesNumber} de ${monthlyTarget}`
    this.series = [Math.round(percentage)]
    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: ['Ventas'],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters

  // Watchers
  @Watch('sales', { immediate: true, deep: true })
  @Watch('target', { immediate: true, deep: true })
  @Watch('executives', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
