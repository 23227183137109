import Component from 'vue-class-component'
import { BaseChart } from './baseChart'
import dayjs from 'dayjs'

@Component({})
export class DetailChart extends BaseChart {
  amount: number
  target: number

  getDate (date) {
    if (date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
    return ''
  }

  getCarFullName (car) {
    const brand = car.version_year.version.model.brand.name
    const model = car.version_year.version.model.name
    return `${brand} ${model}`
  }

  getExecutiveFullName (car) {
    let executive = car.deals?.[0]?.lead?.executive
    if (!executive) {
      executive = car.executive
    }
    if (!executive) {
      executive = car.financing?.sale_order?.deal?.lead?.executive
    }

    if (executive) {
      const name = executive.person.first_name
      const lastName = executive.person.surname
      return `${name} ${lastName}`
    }
    return 'Sin ejecutivo'
  }

  // Getters
  get percentage () {
    return (this.amount / this.target) * 100
  }

  get color () {
    return this.getBarColor(this.percentage)
  }
}
