
  import Component from 'vue-class-component'
  import { BaseChart } from './baseChart'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'

@Component({})
  export default class Accumulateds extends BaseChart {
  @Prop() sales
  @Prop() purchases
  @Prop() credits

  @Prop() prevMonthSales
  @Prop() prevMonthPurchases
  @Prop() prevMonthCredits

  @Prop() dates

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      return {
        start: this.dates.start,
        end: currentDate.format('YYYY-MM-DD'),
      }
    } else return this.dates
  }

  generateAccumulated (arr: number[]) {
    const accumulated: number[] = []
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) accumulated.push(arr[0])
      else {
        accumulated.push(arr[i] + accumulated[i - 1])
      }
    }
    return accumulated
  }

  getData () {
    if (
      !this.sales?.sales_stock_aggregate ||
      !this.purchases?.sales_stock_view_aggregate ||
      !this.credits?.loans_evaluation_view_aggregate ||
      !this.prevMonthSales?.sales_stock_aggregate ||
      !this.prevMonthPurchases?.sales_stock_view_aggregate ||
      !this.prevMonthCredits?.loans_evaluation_view_aggregate
    ) return

    const limitDateRange = this.generateDayRange(
      dayjs(this.limitDates.start),
      dayjs(this.limitDates.end),
    )

    const currentMonth = {
      sales: this.sales.sales_stock_aggregate.nodes,
      purchases: this.purchases.sales_stock_view_aggregate.nodes,
      credits: this.credits.loans_evaluation_view_aggregate.nodes,
    }

    const prevMonth = {
      sales: [],
      purchases: [],
      credits: [],
    }

    if (
      this.prevMonthSales.sales_stock_aggregate !== undefined &&
      this.prevMonthPurchases.sales_stock_view_aggregate !== undefined &&
      this.prevMonthCredits.loans_evaluation_view_aggregate !== undefined
    ) {
      prevMonth.sales = this.prevMonthSales.sales_stock_aggregate.nodes
      prevMonth.purchases = this.prevMonthPurchases.sales_stock_view_aggregate.nodes
      prevMonth.credits = this.prevMonthCredits.loans_evaluation_view_aggregate.nodes
    }

    const categories = this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)

    const prevMonthDayRange = this.generateDayRange(
      dayjs(this.dates.start).subtract(1, 'month').startOf('month'),
      dayjs(this.dates.start).subtract(1, 'month').endOf('month')
    ).slice(0,
      this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.start).endOf('month')).length - 1
    )

    const mondays = []
    categories.forEach(category => {
      if (category.day() === 1) {
        mondays.push(category)
      }
    })

    const count = {
      dailySales: [],
      dailyPurchases: [],
      dailyCredits: [],
      prevMonthSales: [],
      prevMonthPurchases: [],
      prevMonthCredits: [],
    }

    limitDateRange.forEach(category => {
      const day = dayjs(category)

      count.dailySales.push(currentMonth.sales.filter(sale =>
        dayjs(this.convertUtc(sale.sold_date)).isSame(day, 'day')).length)

      count.dailyPurchases.push(currentMonth.purchases.filter(purchase =>
        dayjs(this.convertUtc(purchase.stock_created)).isSame(day, 'day')).length)

      count.dailyCredits.push(currentMonth.credits.filter(credit =>
        dayjs(this.convertUtc(credit.signed_date)).isSame(day, 'day')).length)
    })

    // Prev month
    prevMonthDayRange.forEach(category => {
      const day = dayjs(category)

      count.prevMonthSales.push(prevMonth.sales.filter(sale =>
        dayjs(this.convertUtc(sale.sold_date)).isSame(day, 'day')).length)

      count.prevMonthPurchases.push(prevMonth.purchases.filter(purchase =>
        dayjs(this.convertUtc(purchase.stock_created)).isSame(day, 'day')).length)

      count.prevMonthCredits.push(prevMonth.credits.filter(credit =>
        dayjs(this.convertUtc(credit.signed_date)).isSame(day, 'day')).length)
    })

    const accumulateds = {
      sales: this.generateAccumulated(count.dailySales),
      purchases: this.generateAccumulated(count.dailyPurchases),
      credits: this.generateAccumulated(count.dailyCredits),
      prevMonthSales: this.generateAccumulated(count.prevMonthSales),
      prevMonthPurchases: this.generateAccumulated(count.prevMonthPurchases),
      prevMonthCredits: this.generateAccumulated(count.prevMonthCredits),
    }

    for (let i = limitDateRange.length; i < categories.length; i++) {
      accumulateds.sales.push(null)
      accumulateds.purchases.push(null)
      accumulateds.credits.push(null)
    }

    const annotations = mondays.map(monday => {
      return {
        x: monday.format('DD'),
        borderColor: '#FF0000',
      }
    })

    this.series = [
      { name: 'Compras', data: accumulateds.purchases.slice(0, categories.length), color: '#6185DB' },
      { name: 'Ventas', data: accumulateds.sales.slice(0, categories.length), color: '#13CE66' },
      { name: 'Créditos', data: accumulateds.credits.slice(0, categories.length), color: '#7D67BC' },
      {
        name: 'Compras Mes Pasado',
        data: accumulateds.prevMonthPurchases.slice(0, categories.length),
        color: '#6185DB4C',
      },
      { name: 'Ventas Mes Pasado', data: accumulateds.prevMonthSales.slice(0, categories.length), color: '#13CE664C' },
      {
        name: 'Créditos Mes Pasado',
        data: accumulateds.prevMonthCredits.slice(0, categories.length),
        color: '#7D67BC4C',
      },
    ]

    this.chartOptions = {
      labels: categories,
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      annotations: {
        xaxis: annotations,
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0, 0, 0, 3, 3, 3],
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD')
          },
        },
      },
      yaxis: {
        stepSize: 2,
        labels: {
          formatter (val) {
            return Math.floor(val)
          },
        },
      },
    }
  }

  get salesNumber () {
    if (this.sales.sales_stock_aggregate?.aggregate?.count) {
      return this.sales.sales_stock_aggregate.aggregate.count
    } else return 0
  }

  get purchasesNumber () {
    if (this.purchases.sales_stock_view_aggregate?.aggregate?.count) {
      return this.purchases.sales_stock_view_aggregate.aggregate.count
    } else return 0
  }

  get creditsNumber () {
    if (this.credits.loans_evaluation_view_aggregate?.aggregate?.count) {
      return this.credits.loans_evaluation_view_aggregate.aggregate.count
    } else return 0
  }

  @Watch('sales', { immediate: false, deep: true })
  @Watch('purchases', { immediate: false, deep: true })
  @Watch('credits', { immediate: false, deep: true })
  @Watch('prevMonthSales', { immediate: false, deep: true })
  @Watch('prevMonthPurchases', { immediate: false, deep: true })
  @Watch('prevMonthCredits', { immediate: false, deep: true })
  @Watch('dates', { immediate: false, deep: true })
  update () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
