export const filters = (dates, idExecutive: number) => {
  return {
    credit: {
      aggregate: {
        _and: [
          { signed_date: dates },
          { closing_reason: { type: { name: { _eq: 'signed' } } } },
          { closing_reason_deal: { _eq: 'successful_sale' } },
          { executive: { id: { _eq: idExecutive } } },
        ],
      },
    },
    soldDate: {
      aggregate: {
        sold_date: dates,
        stock_detail: {
          executive: { id: { _eq: idExecutive } },
        },
      },
    },
    stockCreatedDate: {
      aggregate: {
        _and: {
          stock_created: dates,
          executive: { id: { _eq: idExecutive } },
        },
      },
    },
  }
}
