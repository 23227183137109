
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Debounce } from '@/utils/decorators'
  import { transmissionColors } from '@/utils/transmissionColors'

@Component({})
  export default class CarsByTransmission extends BaseChart {
  transmissions = []
  stockSpecsAttribute = []
  @Prop() stockSpecs
  @Prop() cars
  transmissionColors = transmissionColors

  getCountByTransmission (transmission) {
    return this.stockSpecsAttribute
      .filter(car => {
        let carTransimission = car.componentValue.value
        if (carTransimission === 'CVT' || carTransimission === 'Automatizada') {
          carTransimission = 'Automática'
        }
        return carTransimission === transmission
    }).length
  }

  async getData () {
    if (!this.stockSpecs) return
    const stock = this.stockSpecs.salesStockView
    if (!stock) return

    const carSpecs = this.cars
    this.stockSpecsAttribute = carSpecs

    let transmissions = []
    carSpecs.forEach(car => {
      let carTransimission = car.componentValue.value
      if (carTransimission === 'CVT' || carTransimission === 'Automatizada') {
        carTransimission = 'Automática'
      }
      if (!transmissions.includes(carTransimission)) {
        transmissions.push(carTransimission)
      }
    })
    transmissions = transmissions.sort((prev, next) => {
      return this.getCountByTransmission(next) - this.getCountByTransmission(prev)
    })
    this.transmissions = transmissions
    const colors = transmissions.map(transmission => transmissionColors[transmission])

    this.series = transmissions.map(transmission => {
      return carSpecs
        .filter(car => car.componentValue.value === transmission)
        .length
    })
    this.chartOptions = {
      colors,
      stroke: {
        lineCap: 'round',
      },
      legend: {
        show: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: transmissions,
      fill: {
        type: 'solid',
      },
    }
  }

  // Getters

  // Watchers
  @Watch('stockSpecs', { immediate: true, deep: true })
  @Watch('cars', { immediate: true, deep: true })
  @Debounce(500)
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
